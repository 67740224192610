<template>
    <v-dialog
        v-model="dialog"
        persistent
        light
        max-width="600px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="primary" class="ml-4" v-bind="attrs" v-on="on">{{ $t('password_forgotten_dialog.button_open') }}</v-btn>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('password_forgotten_dialog.title') }}</v-card-title>
            <v-card-text class="mt-4">
                <forgot-password/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                    {{ $t('password_forgotten_dialog.button_close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ForgotPassword from '../ForgotPassword';

export default {
    name: 'PasswordForgotDialog',

    components: {ForgotPassword},

    data: () => ({
        dialog: false,
    }),
};
</script>

<style scoped>

</style>
