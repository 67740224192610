<template>
    <v-row no-gutters dense>
        <v-col cols="4" class="left-sidebar px-12 py-8">
            <v-container>
                <v-layout class="mt-10">
                    <v-flex>
                        <v-card light elevation="0">
                            <div style="transform: translateX(9vw); min-width: 360px;">
                                <register-or-login-with-email-form v-model="registerNewAccount"/>
                            </div>
                        </v-card>
                        <v-card v-if="!registerNewAccount" dark flat class="pt-6 mt-12 pa-4" style="transform: translateX(9vw); border: 10px solid black; min-width: 360px;">
                            <register-activator v-model="registerNewAccount"/>
                        </v-card>
                        <v-btn style="display:none;" @click="userSignInGoogle" dark>Google</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-col>
        <v-col cols="8" class="background-bricks px-12 py-9">

        </v-col>
    </v-row>
</template>

<script>
import RegisterActivator from '@/components/RegisterActivator';
import RegisterOrLoginWithEmailForm from '@/components/RegisterOrLoginWithEmailForm';

export default {
    name: 'TheLogin',

    components: {RegisterOrLoginWithEmailForm, RegisterActivator},

    data: () => ({
        registerNewAccount: false,
    }),

    methods: {
        userSignInGoogle () {
            this.$store.dispatch('authentication/userSignIn', {method: 'google-popup'});
        },
    },

    mounted () {
        this.$store.commit('SET_NAVIGATION_MINI', false);
        this.$store.commit('SET_PHOTO_DRAWER', false);
    },
};
</script>

<style scoped lang="scss">

</style>
