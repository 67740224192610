<template>
    <div>
        <h4 class="text-h4 mb-5">{{ $t('register_activator.heading') }}</h4>
        <span class="tiemposText">{{ $t('register_activator.body_text') }}</span>
        <br><br>
        <v-btn outlined small @click="registerNewAccount = true">{{ $t('registration.signup') }}</v-btn>
    </div>
</template>

<script>
export default {
    name: 'RegisterActivator',

    data: function () {
        return {
            registerNewAccount: !!this.value,
        };
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },

    watch: {
        value (newValue) {
            this.registerNewAccount = !!newValue;
        },

        registerNewAccount (newValue) {
            !!newValue !== this.value && this.$emit('input', newValue);
        }
    },
};
</script>

<style scoped>

</style>
