<template>
    <v-flex>
        <v-card-text>
            {{ $t('password_forgotten.title') }}<br>
            <v-form ref="form" @submit.prevent="resetPassword">
                <v-text-field
                    v-model="email"
                    :label="$t('password_forgotten.form_field_email')"
                    :rules="emailRules"
                    required
                ></v-text-field>
                <v-btn type="submit" color="primary" :disabled="email.length < 5">
                    {{ $t('password_forgotten.btn_submit') }}
                    <span class="icon-btn-arrow"></span>
                </v-btn>
            </v-form>
        </v-card-text>
    </v-flex>
</template>
<script>
import { auth } from 'firebase';
import { RULES_EMAIL_REQUIRED, RULES_EMAIL_VALIDATE } from '../utils';

export default {
    data () {
        return {
            email: '',
            emailRules: [
                ...RULES_EMAIL_REQUIRED,
                ...RULES_EMAIL_VALIDATE,
            ],
        };
    },
    methods: {
        resetPassword: function () {
            if (this.$refs.form.validate()) {
                // Bij Firebase de wachtwoord vergeten functie aanroepen
                auth().sendPasswordResetEmail(this.email)
                    .then(() => this.$store.dispatch('statusMessage/success', this.$t('password_forgotten.success_msg')))
                    .catch(err => {
                        console.error(err);
                        this.$store.dispatch('statusMessage/error', this.$t('password_forgotten.error_msg'));
                        return false;
                    });
            } else {
                this.$store.dispatch('statusMessage/error', this.$t('global.incorrect_value'));
            }
        }
    },

    /**
     * Vertalingen vue-i18n
     */
    i18n: {
        messages: {
            en: {
                'password_forgotten': {
                    'title': 'Forgot your password? Fill in your email. Within a few minutes you will receive an email to reset your password.',
                    'form_field_email': 'Email',
                    'success_msg': 'An email is sent to reset your password. Please also check your spam box',
                    'error_msg': 'Email does not exist or is invalid.',
                    'btn_submit': 'Reset password',
                }
            },
        },
    },
};
</script>
