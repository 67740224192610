<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="userSignInSignUp">
        <v-responsive elevation="0" class="pa-2" :aspect-ratio="1" style="border: 10px solid black;">
            <v-card-text>
                <div class="subheading">
                    <template v-if="registerNewAccount"><h1 style="color: black;" class="text-h1 mt-4">{{ $t('registration.create_account') }}</h1></template>
                    <template v-else><h2 style="color: black;" class="text-h2 mt-1">{{ $t('registration.heading_login') }}</h2></template>
                </div>
                <v-text-field v-if="registerNewAccount" light prepend-icon="person" :label="$t('registration.label_name')" v-model="displayName" :rules="displayNameRules" autocomplete="name"></v-text-field>
                <v-text-field light prepend-icon="email" :label="$t('registration.label_email')" type="email" v-model="email" :rules="emailRules" autocomplete="email"></v-text-field>
                <v-text-field light prepend-icon="lock" :label="$t('registration.label_password')" type="password" v-model="password" :rules="passwordRules" autocomplete="current-password"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="registerNewAccount" class="boxed-icon ml-4" tile color="primary" style="padding-left: 0;" type="submit" :disabled="!valid">
                    <v-icon left> mdi-arrow-right</v-icon>
                    {{ $t('login.signup') }}
                </v-btn>
                <v-btn v-else :disabled="!valid" class="boxed-icon" tile color="primary" style="padding-right: 0;" type="submit">
                    {{ $t('login.signin') }}
                    <v-icon right> mdi-arrow-right</v-icon>
                </v-btn>
                <div v-if="!registerNewAccount">
                    <password-forgot-dialog/>
                </div>
            </v-card-actions>
        </v-responsive>
    </v-form>
</template>

<script>
import PasswordForgotDialog from '@/components/dialogs/PasswordForgotDialog';
import { RULES_EMAIL_REQUIRED, RULES_EMAIL_VALIDATE, RULES_NAME_REQUIRED, RULES_NAME_VALIDATE, RULES_PASSWORD_REQUIRED, RULES_PASSWORD_VALIDATE } from '@/utils';

export default {
    name: 'RegisterOrLoginWithEmailForm',

    components: {PasswordForgotDialog},

    data () {
        return {
            registerNewAccount: !!this.value,

            // Formulierdata
            valid: false,
            displayName: '',
            displayNameRules: [
                ...RULES_NAME_REQUIRED,
                ...RULES_NAME_VALIDATE,
            ],
            email: '',
            emailRules: [
                ...RULES_EMAIL_REQUIRED,
                ...RULES_EMAIL_VALIDATE,
            ],
            password: '',
            passwordRules: [
                ...RULES_PASSWORD_REQUIRED,
                ...RULES_PASSWORD_VALIDATE,
            ],
        };
    },

    props: {
        value: {
            required: false,
        }
    },

    watch: {
        value (newValue) {
            this.registerNewAccount = !!newValue;
        },

        registerNewAccount (newValue) {
            !!newValue !== this.value && this.$emit('input', newValue);
        }
    },

    methods: {
        userSignInSignUp () {
            if (this.$refs.form.validate()) {
                if (this.registerNewAccount) {
                    this.$store.dispatch('authentication/userJoin', {
                        email: this.email,
                        password: this.password,
                        displayName: this.displayName,
                    });
                } else {
                    this.$store.dispatch('authentication/userSignIn', {
                        email: this.email,
                        password: this.password
                    }).then(() => this.$router.push('/projects')).catch(() => {});
                }

                this.registerNewAccount = false;
            }
        },
    },
};
</script>

<style scoped>

</style>
